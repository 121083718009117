import React ,{useState,useEffect} from 'react';
import styled from 'styled-components';
import Layout from '../components/layout.component'
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
const Masterclass = () => {
    const [masterClass, setMasterclass] = useState([])

    useEffect(() => {
        const asyncFn = async () => {
            const response = await fetch(`https://api.ufaber.com/api/realschool/v3/masterclasses-list/`, {
                method: 'get',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            let data = await response.json()
            data = data.data
            setMasterclass(data)
        };
        asyncFn()
    }, [])


    return (
        <Layout>
            <MasterClassSection>
            <div id="masterClass_banar" >
                    <div className="masterClass_banar_content ">
                        <h1>Master Classes</h1>
                        <p>Discover a range of classes designed to help your child discover and learn. You can select <br/>individual Classes, or get the pass to experience amazing benefits.</p>
                    </div>
                </div>
                <div id="masterClass_scroll_header">
                    <div className="row-3">
                        {masterClass?.range?.map((date) => (
                            <div class="masterClass_scroll_header_item"  >
                                <a href={`#${ date.date }`} class="date_1 " onclick="changecolor('{{ forloop.counter }}')" >
                                    <h4>{date.date_name}</h4>
                                    <h3 id='' class="class_h3"  >{date.date}</h3>
                                    <p>{date.date_month}</p>
                                </a>
                            </div>
                        ))}


                    </div>
                </div>
                {masterClass?.sessions?.map((masterClassData) => { 
                    return (

                        <div className='header-wrapper'>
                            <div className='row-1'>
                                <div className='row-2'>
                                    <h3>Classes For {masterClassData.date} 
                                    </h3>
                                </div>
                            </div>

                        <div className='row'>
                            {masterClassData?.masterclass_list.map((card) => (

                                <div className="masterclass_cards">
                                    <div className="masterclass_items">
                                        <Link to={`/single-masterclass/id=${card?.master_class_data?.id}`}>
                                            <div className="masterclass_img">
                                                    <img src={card?.master_class_data?.image} alt={card.master_class_data?.title ?? ""} />
                                            </div>
                                            <div className="masterclass_content">
                                                <div className="masterclass_content_left">
                                                    <h4>LIVE ON</h4>
                                                    <h4>{card?.date ?? ""}</h4>
                                                </div>

                                                <div className="masterclass_content_right">
                                                    <h4>TIME SLOTS</h4>
                                                    <h4>{card?.ids_time_data[0]?.slot ?? ""}</h4>
                                                </div>

                                                <FontAwesomeIcon
                                                    icon={faArrowRightLong}
                                                    style={{fontSize:"0.5em"}}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                    </div>

                            ))}
                            </div>
                        </div>
                   )
                    })}
            </MasterClassSection>
        </Layout>
    );
}
const MasterClassSection = styled.section`

#masterClass_scroll_header{
background: #fff;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 30%);
    position: relative;
}
.masterClass_scroll_header_item{
    text-align: center;
}
.masterClass_scroll_header_item a{
    display: block;
    padding: 15px 0px;
    position: relative;
        text-decoration: none;
    color: black;
}
.masterClass_scroll_header_item h4{
    font-weight: 500;
    font-size: 12px;
    color: #6E6E6E;
}
.masterClass_scroll_header_item a h3 {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    margin-top: 7px;
    margin-bottom: 3px;
    transition: 0.4s;
}
.masterClass_scroll_header_item a p {
    font-weight: 500;
    font-size: 13px;
    color: #6E6E6E;
}
#masterClass_banar{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem; 
    margin-top: 2rem;
    justify-content: space-between;
}
.row-3{
   max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.masterClass_banar_content h1{
    font-size: 2.5rem;
    font-family:"Inter";
}
.masterClass_banar_content p {
    width: 70%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin-top: 25px;
}
.row {
     max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
.dial-code-box {
    position: absolute;
    left: 49px;
    font-size: 17px;
    font-weight: 500;
    line-height: 48px;
    opacity: 5.3;
} 

.newSignInModlContent .signUpPopup_tab_btn ul li a {
    border: none;
    border-radius: 0px;
    padding: 6px 15px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
}
.header-wrapper{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding:2rem;
    margin-top:2rem;
}
.row-1{
        flex: 0 0 100%;
    max-width: 100%;
        position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.row-2{
text-align: center!important;
}
.row-2 h3{
background: url(https://therealschool.in/public/static/realschool_new_design/correction-1/img/shape/3.png);
    width: 220px;
    padding: 7px 0px 16px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: gc;
    font-size: 18px;
    color: #fff;
    margin: 0 auto;
}
.masterclass_cards {
   display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 0px;
  }
  .masterclass_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    box-shadow: rgb(17 58 104 / 15%) 0px 4px 25px;
}
  .masterclass_items a {
    color: black;
    text-decoration: none;
  }
  .masterclass_img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    width: 100%;
    height: 250px;

  }
 .masterclass_img img{
    height: 245px !important;
 }
  .masterclass_content {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: 100%;
  }

  .masterclass_content h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }
  .row h3{
    text-align: center!important;
    padding: 7px 0px 16px 0px;
    background-size: cover;
    color: #000;
    margin: 20px;
  }
@media (max-width: 1200px) {
   .image img {
      height: 204px !important;
   }
}
@media (max-width: 991px) {
   .image img {
      height: 231px !important;
   }
}
@media (max-width: 767px) {
   .image img {
      height: auto !important;
   }
}
@media(max-width:500px){
    .row-3{
        grid-template-columns: repeat(auto-fit,minmax(10px,1fr));
        margin:0.5rem;
    }
}
.masterClass_banar_content  h1 {
    font-family: gc;
    color: #264C55;
}
`
export default Masterclass;
